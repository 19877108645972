.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #0C1B4D; /* Replace with your desired background color */
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
@font-face {
  font-family: 'Orbitron';
  src:url('./fonts/Orbitron-VariableFont_wght.ttf') format('truetype');
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.upload-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px; /* Adjust margin as needed */
}

.upload-button:hover {
  background-color: #356F9F;
}

.login-register-span {
  cursor: pointer;
}



