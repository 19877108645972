.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-logo {
    width: 200px; /* Adjust the size as needed */
    height: auto;
}

.loading-animation {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Apply the animation */
}

/* Define the spin animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.download-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.download-button:hover {
    background-color: #356F9F;
}

.proceed-button {
    margin-top: 10px; /* Adjust the margin as needed */
}

.button-container {
    margin-top: 20px; /* Adjust the margin as needed */
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f9f9f9; /* Light gray background */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 9999; /* Ensure the popup appears in front of everything */
}